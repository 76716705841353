import { cva } from 'class-variance-authority';

const sizes = {
  h1: 'text-4xl mb-6',
  h2: 'text-3xl mb-4',
  h3: 'text-2xl mb-3',
  h4: 'text-xl mb-2',
  h5: 'text-lg mb-2',
  h6: 'text-lg mb-1',
};

type HeadlineProps = {
  hierarchy?: keyof typeof sizes;
  size?: keyof typeof sizes;
  className?: string;
  children: React.ReactNode;
};

const classes = cva('text-gray-900 font-extrabold', {
  variants: {
    hierarchy: {
      h1: '',
      h2: '',
      h3: '',
      h4: '',
      h5: '',
      h6: '',
    },
    size: sizes,
  },
  compoundVariants: Object.keys(sizes).map((hierarchy) => ({
    hierarchy,
    size: undefined,
    className: sizes[hierarchy as keyof typeof sizes],
  })) as any,
  defaultVariants: {},
});

const Headline = ({ hierarchy = 'h1', size, className, children }: HeadlineProps) => {
  const Component = hierarchy;

  return <Component className={classes({ hierarchy, size, className })}>{children}</Component>;
};

export default Headline;
