import classNames from 'classnames';
import { decimalPlace } from 'lib/helper/char';

import Character from './Character';

type CountdownRollProps = {
  difference: number;
  hideDays?: boolean;
  hideHours?: boolean;
  hideMinutes?: boolean;
  hideSeconds?: boolean;
  showMilliseconds?: boolean;
  className?: string;
};

const CountdownRoll = ({
  difference,
  hideDays,
  hideHours,
  hideMinutes,
  hideSeconds,
  showMilliseconds,
  className,
}: CountdownRollProps) => {
  const formatValues = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((difference % (1000 * 60)) / 1000),
    milliseconds: Math.floor(difference % 1000),
  };

  return (
    <div className={classNames('flex justify-center gap-2 font-bold', className)}>
      {!hideDays && (
        <>
          <div className="flex">
            <Character value={decimalPlace(formatValues.days, 0, 3)} characterSet="numbers" />
            <Character value={decimalPlace(formatValues.days, 1, 3)} characterSet="numbers" />
            <Character value={decimalPlace(formatValues.days, 2, 3)} characterSet="numbers" />
          </div>
          <div className="font-mono text-[1em] leading-[2]"> </div>
        </>
      )}
      {!hideHours && (
        <>
          <div className="flex">
            <Character value={decimalPlace(formatValues.hours, 0, 2)} characterSet="numbers" />
            <Character value={decimalPlace(formatValues.hours, 1, 2)} characterSet="numbers" />
          </div>
          <div className="font-mono text-[1em] leading-[2]">:</div>
        </>
      )}
      {!hideMinutes && (
        <>
          <div className="flex">
            <Character value={decimalPlace(formatValues.minutes, 0, 2)} characterSet="numbers" />
            <Character value={decimalPlace(formatValues.minutes, 1, 2)} characterSet="numbers" />
          </div>
          <div className="font-mono text-[1em] leading-[2]">:</div>
        </>
      )}
      {!hideSeconds && (
        <div className="flex">
          <Character value={decimalPlace(formatValues.seconds, 0, 2)} characterSet="numbers" />
          <Character value={decimalPlace(formatValues.seconds, 1, 2)} characterSet="numbers" />
        </div>
      )}
      {showMilliseconds && (
        <>
          <div className="font-mono text-[1em] leading-[2]">.</div>
          <div className="flex">
            <Character value={decimalPlace(formatValues.milliseconds, 0, 3)} characterSet="numbers" duration={0} />
            <Character value={decimalPlace(formatValues.milliseconds, 1, 3)} characterSet="numbers" duration={0} />
            <Character value={decimalPlace(formatValues.milliseconds, 2, 3)} characterSet="numbers" duration={0} />
          </div>
        </>
      )}
    </div>
  );
};

export default CountdownRoll;
