import classNames from 'classnames';
import { useMemo } from 'react';

import Character from './Character';

type WordRollProps = {
  word: string;
  duration?: number;
  className?: string;
};

const WordRoll = ({ word, duration = 1, className }: WordRollProps) => {
  const value = word.split('');
  const letters = useMemo(() => value, [value]);

  return (
    <div className={classNames('flex justify-center gap-4 font-bold', className)}>
      {letters.map((_, index) => (
        <Character
          key={index}
          value={(value[index] as string).charCodeAt(0) - 65}
          duration={duration}
          characterSet="letters"
        />
      ))}
    </div>
  );
};

export default WordRoll;
