export const shuffleString = (string: string): string => {
  if (string.length <= 1) {
    return string;
  }
  const shuffledString = string
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  if (shuffledString === string) {
    return shuffleString(string);
  }

  return shuffledString;
};

export const decimalPlace = (number: number, place: number, overallPlaces: number) => {
  const numberArray = number.toString().split('');
  const lengthDifference = overallPlaces - numberArray.length;

  if (place - lengthDifference < 0) {
    return 0;
  }

  let computedPlace;

  if (lengthDifference >= 0) {
    computedPlace = place - lengthDifference;
  } else {
    computedPlace = place;
  }

  return parseInt(number.toString().split('')[computedPlace] as string);
};
