import { Prisma } from '@prisma/client';
import { CreateTRPCProxyClient } from '@trpc/client';
import turfDistance from '@turf/distance';
import { point as turfPoint } from '@turf/helpers';
import { AppRouter } from 'server/api/root';

export const parsePrismaJson = (json: Prisma.JsonValue) => {
  if (typeof json === 'string') {
    return JSON.parse(json);
  }
  return json;
};

export const getAssetUrl = (asset: string) => {
  return `https://${process.env.NEXT_PUBLIC_MINIO_URL}/quizzlybear/${asset}`;
};

export const uploadAsset = async (file: File, client: CreateTRPCProxyClient<AppRouter>) => {
  try {
    const { url, name, path } = await client.misc.presignedUrl.mutate();

    const renamedFile = new File([file], name, { type: file.type });

    await fetch(url, {
      method: 'PUT',
      body: renamedFile,
    });

    return path;
  } catch (error) {
    console.error(error);
  }
};

export const calculateDistance = (latFrom?: number, lngFrom?: number, latTo?: number, lngTo?: number) => {
  if (!latFrom || !lngFrom || !latTo || !lngTo) {
    return 0;
  }

  const from = turfPoint([latFrom, lngFrom]);
  const to = turfPoint([latTo, lngTo]);

  const distance = turfDistance(from, to, {
    units: 'meters',
  });

  return distance;
};
