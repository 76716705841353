import { cva } from 'class-variance-authority';
import classNames from 'classnames';

import LoadingIndicator from './LoadingIndicator';

type OptionButtonProps = {
  color?: 'primary' | 'dark' | 'gray' | 'white' | 'green' | 'orange' | 'red';
  type?: 'button' | 'submit';
  option: string;
  loading?: boolean;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  children: React.ReactNode;
  ref?: React.Ref<HTMLButtonElement>;
  onClick?: () => void;
};

const OptionButton = (props: OptionButtonProps) => {
  const { color, loading, disabled, selected, onClick, type = 'button', className } = props;

  return (
    <button
      type={type}
      className={baseClasses({
        color,
        loading,
        disabled,
        selected,
        className,
      })}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <Content {...props} />
    </button>
  );
};

const Content = ({ color, option, loading, children }: OptionButtonProps) => {
  return (
    <span className="relative block">
      <LoadingIndicator
        className={classNames(
          'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300',
          {
            'opacity-0': !loading,
            'opacity-100': loading,
          }
        )}
        color={color === 'white' || color === 'gray' ? 'dark' : 'white'}
      />
      <span
        className={classNames('flex items-start justify-start gap-x-1.5 text-left transition-opacity duration-300', {
          'opacity-100': !loading,
          'opacity-0': loading,
        })}
      >
        <span className="text-xl font-bold opacity-60">{option}</span>
        <span className="px-1.5 [hyphens:auto]">{children}</span>
      </span>
    </span>
  );
};

const baseClasses = cva('py-2.5 w-full rounded-xl font-semibold text-lg transition-colors px-4 flex', {
  variants: {
    color: {
      dark: 'bg-gray-900 text-white',
      gray: 'bg-gray-200 text-gray-900',
      white: 'bg-white text-gray-900',
      primary: 'bg-primary-500 text-white',
      green: 'bg-green-500 text-white',
      orange: 'bg-orange-500 text-white',
      red: 'bg-red-500 text-white',
    },
    loading: {
      true: 'cursor-default',
      false: null,
    },
    disabled: {
      true: 'cursor-default',
      false: null,
    },
    selected: {
      true: null,
      false: null,
    },
  },
  compoundVariants: [
    {
      color: 'dark',
      disabled: false,
      // className: 'hover:bg-gray-800',
    },
    {
      color: 'gray',
      disabled: false,
      // className: 'hover:bg-gray-300',
    },
    {
      color: 'white',
      disabled: false,
      // className: 'hover:bg-gray-200',
    },
    {
      color: 'primary',
      disabled: false,
      // className: 'hover:bg-primary-600',
    },
    {
      color: 'green',
      disabled: false,
      // className: 'hover:bg-green-600',
    },
    {
      color: 'orange',
      disabled: false,
      // className: 'hover:bg-orange-600',
    },
    {
      color: 'red',
      disabled: false,
      // className: 'hover:bg-red-600',
    },

    {
      color: 'dark',
      disabled: true,
      selected: false,
      className: 'disabled:bg-gray-700 disabled:text-gray-200',
    },
    {
      color: 'gray',
      disabled: true,
      selected: false,
      className: 'disabled:bg-gray-200 disabled:text-gray-500',
    },
    {
      color: 'white',
      disabled: true,
      selected: false,
      className: 'disabled:bg-white disabled:text-gray-500',
    },
    {
      color: 'primary',
      disabled: true,
      selected: false,
      className: 'disabled:bg-primary-300 disabled:text-primary-100',
    },
    {
      color: 'green',
      disabled: true,
      selected: false,
      className: 'disabled:bg-green-300 disabled:text-green-100',
    },
    {
      color: 'orange',
      disabled: true,
      selected: false,
      className: 'disabled:bg-orange-300 disabled:text-orange-100',
    },
    {
      color: 'red',
      disabled: true,
      selected: false,
      className: 'disabled:bg-red-300 disabled:text-red-100',
    },
  ],
  defaultVariants: {
    color: 'dark',
    loading: false,
    disabled: false,
    selected: false,
  },
});

export default OptionButton;
