import classNames from 'classnames';

type ParagraphProps = {
  size?: 'md' | 'lg';
  className?: string;
  children: React.ReactNode;
};

const Paragraph = ({ size = 'lg', className, children }: ParagraphProps) => {
  return <p className={classNames('mb-4 text-gray-800', {
    'text-lg': size === 'lg',
    'text-base': size === 'md',
  },className)}>{children}</p>;
};

export default Paragraph;
