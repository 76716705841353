import classNames from 'classnames';

type LoadingIndicatorProps = {
  color?: 'primary' | 'dark' | 'white';
  size?: 16 | 18 | 20 | 24 | 36;
  className?: string;
};

const LoadingIndicator = ({ color = 'dark', size = 20, className }: LoadingIndicatorProps) => {
  return (
    <div className={className} style={{ width: size, height: size }}>
      <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={classNames({
            'stroke-primary-500': color === 'primary',
            'stroke-gray-900': color === 'dark',
            'stroke-white': color === 'white',
          })}
          cx="12"
          cy="12"
          r="10"
          strokeWidth="2.5"
          strokeOpacity="0.2"
          fill="none"
        />
        <circle
          className={classNames('animate-loading origin-center', {
            'stroke-primary-500': color === 'primary',
            'stroke-gray-900': color === 'dark',
            'stroke-white': color === 'white',
          })}
          cx="12"
          cy="12"
          r="10"
          strokeWidth="2.5"
          pathLength="100"
          strokeDasharray="100 100"
          strokeLinecap="round"
          fill="none"
        />
      </svg>
    </div>
  );
};

export default LoadingIndicator;
