import { motion } from 'framer-motion';

type CharacterProps = {
  value: number;
  duration?: number;
  characterSet?: 'numbers' | 'letters' | 'symbols';
};

const characterSetValues = {
  numbers: (
    <>
      0<br />1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9
    </>
  ),
  letters: (
    <>
      A<br />B<br />C<br />D<br />E<br />F<br />G<br />H<br />I<br />J<br />K<br />L<br />M<br />N<br />O<br />P<br />Q
      <br />R<br />S<br />T<br />U<br />V<br />W<br />X<br />Y<br />Z
    </>
  ),
};

const Character = ({ value, duration = 0.5, characterSet = 'letters' }: CharacterProps) => {
  const characters = characterSet === 'numbers' ? characterSetValues.numbers : characterSetValues.letters;
  return (
    <div className="pointer-events-none h-[1em] w-[1em] shrink-0 basis-[1em] select-none overflow-hidden py-[1em]">
      <motion.div
        initial={{ y: '0em' }}
        animate={{ y: `${value * -2}em` }}
        transition={{ duration }}
        className="-mt-[1em] text-center text-[1em] leading-[2]"
      >
        {characters}
      </motion.div>
    </div>
  );
};

export default Character;
