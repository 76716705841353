import classNames from 'classnames';
import { HTMLProps } from 'react';

type ContainerProps = HTMLProps<HTMLDivElement> & {
  mobile?: boolean;
  children: React.ReactNode;
};

const Container = ({ mobile, className, children, ...props }: ContainerProps) => {
  return (
    <div
      className={classNames(
        'mx-auto w-full',
        {
          'md:max-w-7xl': !mobile,
          'md:max-w-xl': mobile,
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;
